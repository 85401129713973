<template>
  <div class="probasics">
    <div class="schsoolhader">
      <span>{{ projectidname }}</span>

      <span slot="reference" class="referencecss" style="right: 10%; cursor: pointer; position: absolute"
        @click="getback">
        <i class="el-icon-sort" style="transform: rotate(270deg)"></i>
        返回上一级</span>
    </div>
    <div class="probasicsmain">
      <div class="probasicsnav" :style="proradio1booaln == '自编文本' ? 'height: 1150px' : ''">
        <div class="probasicsdiv" @click="homepage(0)" :style="probasicbolan == 0 ? 'background-color: #46507C;' : ''">
          <i class="el-icon-menu"></i>
          <span>首页设置</span>
        </div>
        <div class="probasicsdiv" @mouseleave="mouseoutcaozuo(item)" v-for="(item, index) in probasicsdata" :key="index"
          @click="probasicheked(index, item)" :style="probasicbolan == index + 1 ? 'background-color: #46507C;' : ''
        ">
          <i class="el-icon-menu"></i>
          <span class="menu-span">{{ item.name }}</span>
          <i class="el-icon-circle-plus-outline" @click="probasicoperation(index)"></i>
          <div class="probahandle" v-show="probasioperation == index">
            <div v-for="(va, li) in probasdata" :key="li" :class="probasinoe == li ? 'operation' : ''"
              @click="confirmbasi(li, item.value, va.value, item)">
              {{ va.value }}
            </div>
          </div>
        </div>

        <el-button v-show="probasicsboolan" @click="dialogxinjian" style="margin-left: 15px">新建项目</el-button>
        <el-dialog :title="prodialogtitle" class="dialogcss" :visible.sync="dialogVisible" width="30%"
          :before-close="handleClose">
          <div class="dialogdiv">
            <el-input v-model="probasicsname" placeholder="请输入标题名称"></el-input>

            <el-checkbox v-show="prodialogVisilevel" v-model="checkedfeile">设置文件密码</el-checkbox>

            <el-input v-show="checkedfeile" v-model="checkedfeilepass" placeholder="请输入密码"></el-input>

            <div class="dialogbuton">
              <el-button round @click="(dialogVisible = false), (prodialogVisilevel = false)">取消</el-button>
              <el-button type="primary" round @click="operationconfrm">确定</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
      <div class="probasicscenter" v-show="homepagefalse">
        <div style="width: 200px">
          <div class="probasicdata" @mouseleave="mouseoutcaozuo(item)" v-for="(item, index) in prorjectleveldata"
            :key="index" :class="procss46507 == index ? 'css46507c' : ''" @click="levecsscenter(index, item)">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <span>{{ item.name }}</span>
            </el-tooltip>

            <i class="el-icon-circle-plus-outline" @click="caozuoprolevel(item, index)"></i>

            <div class="probahandle" v-show="projectlevelcaozuo == index">
              <div v-for="(va, li) in probasdata" :key="li" :class="probasinoe == li ? 'operation' : ''" @click="
        confirmbasi(li, item.value, va.value, item),
        li < 2 ? (prodialogVisilevel = true) : ''
        ">
                {{ va.value }}
              </div>
            </div>
          </div>
          <el-button style="margin-left: 50px; margin-top: 40px" v-show="probasicsboolaneds"
            @click="dialogxinjian(), (prodialogVisilevel = true)">新建项目</el-button>
        </div>
      </div>
      <div class="probasicsright" :style="proradio1booaln == '自编文本' ? 'height: 1150px' : ''">
        <div class="probasri" v-show="homepagefalse == false">
          <div class="probastitle">
            <div class="probasblue"></div>
            <div class="probasbann">banner图设置</div>
          </div>
          <div class="probasbottom">
            <div class="probasimgtit">设置标题底图</div>

            <el-upload :action="'/admin/Upload/upload'" list-type="picture-card" :on-success="bannerimgadd"
              class="probasimgupload" :file-list="fileList">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                    <i class="el-icon-download"></i>
                  </span>
                  <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleimg">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <span class="probaszs">请上传1920px*300px大小尺寸的图，且不超过1M</span>
          </div>
        </div>
        <div class="probascentre" v-show="homepagefalse == false">
          <div class="procentre">
            <div class="probascenblue"></div>
            <div class="probascenname">{{ prodlititleinputeds }}</div>

            <i class="el-icon-edit" @click="prodlititlebianji"></i>

            <el-dialog title="修改标题" :visible.sync="prodlititle" width="30%">
              <div class="procendialog">
                <el-input v-model="prodlititleinput" placeholder="请输入标题"></el-input>
                <el-upload :action="'/admin/Upload/upload'" list-type="picture-card"
                  :on-success="prodlititlebianjiosuccess" :file-list="probasicsbuploadimgdata.length == 0
        ? ''
        : probasicsbuploadimgdata
        ">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                        <i class="el-icon-download"></i>
                      </span>
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <span>请上传图片文件</span>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="prodlititle = false">取 消</el-button>
                <el-button type="primary" @click="confqueding">确 定</el-button>
              </span>
            </el-dialog>
          </div>
          <el-radio-group v-model="proradiobatch" @change="prorradio" style="margin: 20px">
            <el-radio-button label="文件上传">文件上传</el-radio-button>
            <el-radio-button label="批量上传">批量上传</el-radio-button>
          </el-radio-group>
          <div class="probasfile" v-show="proradiobatch == '文件上传'">
            <div>
              <div class="profilename">文件标题</div>
              <div class="profiledata">
                <el-input v-model="filename" placeholder="请输入文件标题"></el-input>
                <el-upload class="upload-demo" :action="'/admin/Upload/upload'" :on-preview="handlePreviewfile"
                  :on-remove="handleRemovefile" :before-remove="beforeRemovefile" :on-success="bannerimgaddfile"
                  multiple :limit="3" :on-exceed="handleExceedfile">
                  <el-button size="small">点击上传</el-button>
                </el-upload>
              </div>
              <div class="propass">
                <el-checkbox v-model="propassboolan">加密</el-checkbox>
                <!-- <span>设置密码</span> -->
                <el-input v-show="propassboolan" v-model="propassname" placeholder="请输入密码"></el-input>
              </div>
              <el-button type="primary" class="profilebut" @click="savedatapro">保存</el-button>
            </div>
            <div class="profilelist">
              <div class="profiletit">
                <span>文件列表</span>
                <span>操作</span>
              </div>
              <div class="profiledataname">
                <div v-for="(item, index) in probasicfilelistdata" :key="index">
                  <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                    <span>
                      {{ item.name }}
                    </span>
                  </el-tooltip>

                  <i class="el-icon-delete" @click="confirmdel(item), (profiledata = index)"></i>

                  <i class="el-icon-view" @click="browsepdf(item)"></i>

                  <i class="el-icon-edit-outline" @click="
        confirmbasi(0, item.values, '文件列表操作', item),
        (profiledata = index)
        "></i>
                </div>
              </div>
            </div>
            <el-dialog title="提示" :visible.sync="dialogVisiblefiled" width="30%" class="profileeldialogdata"
              :before-close="handleClosefile">
              <div class="profiledialog">
                <img src="../assets/椭圆 1.png" alt="" />
                <span>是否确定删除？</span>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="(dialogVisiblefiled = false), (proreresndex = -1)" round style="margin-right: 15px">取
                  消</el-button>
                <el-button type="primary" @click="dialogconfirmdel()" round
                  style="background-color: #323b61; border: 0">确 定</el-button>
              </span>
            </el-dialog>
          </div>

          <div class="probasfilebatch" v-show="proradiobatch == '批量上传'">
            <div class="probasbatchcss">
              <el-checkbox v-model="checkedbatch">是否设置密码</el-checkbox>
              <el-input v-show="checkedbatch" v-model="inputbatchpass" placeholder="请输入密码"></el-input>
              <el-button type="primary" @click="biaotione">保存</el-button>
            </div>
            <el-upload class="upload-demo" drag :action="'/admin/Upload/upload'" multiple :on-success="biaotipiliang">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件夹拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">只能上传文件夹</div>
            </el-upload>
          </div>
        </div>

        <div class="proachievement" v-show="homepagefalse == false">
          <div class="proachievnav">
            <div class="proachiebule"></div>
            <div class="proachietit">{{ prodlititleinputedstwo }}</div>

            <i class="el-icon-edit" @click="prodlititlechenguo"></i>

            <div class="proachenguocss" v-show="prodlititlettwo">
              <el-input v-model="prodlititleinputtwo" placeholder="请输入内容"></el-input>
              <el-button type="primary" @click="prodlititletijiao">保存</el-button>
            </div>
          </div>
          <div class="proachiedata">
            <div class="proachiuoload">
              <el-upload :action="'/admin/Upload/upload'" :before-upload="beupload" list-type="picture-card"
                :on-preview="handlePictureCardPreviewresult" :on-remove="handleRemoveresult"
                :on-success="bannerimgchietit">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div class="proachiinput">
              <el-input v-model="proachititle" placeholder="请输入文件标题"></el-input>
              <el-input v-model="proachisrc" placeholder="请输入图片连接的网站(选填)"></el-input>
              <el-button type="primary" @click="savechietitdata">保存</el-button>
            </div>

            <div class="proachfile">
              <div>文件列表</div>
              <div class="proachfilecss">
                <el-carousel v-if="probasicchengguolist" :interval="4000" type="card" height="200px"
                  style="width: 800px" :autoplay="probasicchengguolist.length >= 4 ? true : false">
                  <el-carousel-item v-for="(item, index) in probasicchengguolist" :key="index">
                    <div>
                      <img :src="item.url" alt="" />
                      <div @click="proresultindex(index, item)" style="
                          width: 400px;
                          height: 200px;
                          position: absolute;
                          top: 0px;
                          opacity: 0.5;
                          text-align: center;
                          line-height: 200px;
                          background: rgb(51, 51, 51);
                          color: #fff;
                          font-weight: 800;
                        ">
                        删除文件
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>

        <div class="probascentre" v-show="homepagefalse">
          <div class="procentre">
            <div class="probascenblue"></div>
            <div class="probascenname">文件设置</div>
          </div>
          <el-radio-group v-model="proradio1booalneds" @change="prorradioeds" style="margin: 20px">
            <el-radio-button label="文件上传">文件上传</el-radio-button>
            <el-radio-button label="自编文本">自编文本</el-radio-button>
            <el-radio-button label="批量上传">批量上传</el-radio-button>
          </el-radio-group>
          <div class="probasfile" v-show="proradio1booalneds == '文件上传'">
            <div>
              <div class="profilename">文件标题</div>
              <div class="profiledata">
                <el-input v-model="filenameeds" placeholder="请输入文件标题"></el-input>
                <el-upload class="upload-demo" :action="'/admin/Upload/upload'" :on-preview="handlePreviewfile"
                  :on-remove="handleRemovefile" :before-remove="beforeRemovefile" :on-success="bannerimgaddfileeds"
                  multiple :limit="3" :on-exceed="handleExceedfile">
                  <el-button size="small">点击上传</el-button>
                </el-upload>
              </div>
              <div class="propass">
                <el-checkbox v-model="propassboolaneds">加密</el-checkbox>
                <!-- <span>设置密码</span> -->
                <el-input v-show="propassboolaneds" v-model="propassnameeds" placeholder="请输入密码"></el-input>
              </div>
              <el-button type="primary" class="profilebut" @click="savedataproeds">保存</el-button>
            </div>
            <div class="profilelist">
              <div class="profiletit">
                <span>文件列表</span>
                <span>操作</span>
              </div>
              <div class="profiledataname" v-if="prorjectleveldata[propaindexid]">
                <div v-for="(item, index) in prorjectleveldata[propaindexid]
        .children" :key="index">
                  <span>
                    {{ item.name }}
                  </span>
                  <i class="el-icon-delete" @click="confirmdeleds(item), (profiledata = index)"></i>
                  <i class="el-icon-view" @click="browsepdf(item)"></i>

                  <i class="el-icon-edit-outline" @click="
        confirmbasieds(0, item.values, '文件列表操作', item),
        (profiledata = index)
        "></i>
                </div>
              </div>
            </div>
            <el-dialog title="提示" :visible.sync="dialogVisiblefilededs" width="30%" class="profileeldialogdata">
              <div class="profiledialog">
                <img src="../assets/椭圆 1.png" alt="" />
                <span>是否确定删除？</span>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="(dialogVisiblefilededs = false), (proreresndex = -1)" round
                  style="margin-right: 15px">取 消</el-button>
                <el-button type="primary" @click="dialogconfirmdeleds()" round
                  style="background-color: #323b61; border: 0">确 定</el-button>
              </span>
            </el-dialog>

            <el-dialog :title="'文件编辑'" class="dialogcss" :visible.sync="dialogVisibleeds" width="30%">
              <div class="dialogdiv">
                <el-input style="margin-bottom: 15px" v-model="probasicsname" placeholder="请输入标题名称"></el-input>
                <div class="dialogbuton" style="text-align: center">
                  <el-button round @click="dialogVisibleeds = false">取消</el-button>
                  <el-button type="primary" round @click="operationconfrmeds">确定</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
          <div class="prorichtext" v-show="proradio1booalneds == '自编文本'">
            <el-input v-model="proeditorContenttitle" placeholder="请输入文本标题"></el-input>
            <el-checkbox v-model="proeditochecked">是否加密</el-checkbox>
            <el-input v-model="proeditorContentboolan" placeholder="请输入密码" v-show="proeditochecked"></el-input>


            <div id="editorElem" style="
                text-align: left;
                width: 1200px;
                margin-left: 20px;
                margin-bottom: 15px;
              "></div>

            <el-button type="primary" style="margin-left: 15px; background-color: #323b61; border: 0"
              @click="getContent">保存</el-button>
          </div>

          <div class="prorichtexteds" v-show="proradio1booalneds == '批量上传'">
            <el-upload ref="batchup" class="upload-demo" drag :action="'/admin/Upload/upload'"
              :on-success="batchuphtexteds" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件夹拖到此处，或<em>点击上传文件夹</em>
              </div>
            </el-upload>

            <el-checkbox v-model="checkedtchfile">是否设置密码</el-checkbox>
            <el-input style="width: 200px" v-show="checkedtchfile" v-model="checkedtchinput"
              placeholder="请输入密码"></el-input>
            <el-button type="primary" @click="savebatchfile" v-show="checkedtchfile">保存</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="browscss">
      <el-dialog title="文件预览" :visible.sync="browsefileVisible" width="80%" :before-close="handleClosebrows">
        <iframe :src="iframefileurl" frameborder="0" width="100%" height="600px"></iframe>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="browsefileVisible = false">取 消</el-button>
          <el-button type="primary" @click="browsefileVisible = false"
            >确 定</el-button
          > -->
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
import Wangeditor from "./wangeditor/wangeditor.vue";
import {
  getList,
  bannerimg,
  getbanner,
  changeList,
  Listtwice_add,
  Listtwice_listdata,
  addtwice_change,
} from "../api/api";
export default {
  data() {
    return {
      probasicsdata: [], //项目数据

      probasicfilelistdata: [], //文件列表数据

      probasicchengguolist: [], //成果展示文件列表

      probasdata: [{ value: "修改名称" }, { value: "新建" }, { value: "删除" }], //操作方法

      proradiobatch: "文件上传", //首页单文件上传或者批量上传

      probasicsboolan: false, //选中的项目操作的

      probasicbolan: 0, //选中的项目

      probasioperation: -1, //是否展示操作

      probasicsname: "", //输入的修改名称

      probasinoe: -1, //选中的一级下标

      procss46507: 0, //二级项目选中样式

      probname: false, //选择操作

      dialogVisible: false, //设置弹出框

      prodialogtitle: "", //弹窗的名称

      // 上传banner图的属性
      dialogImageUrl: "",
      dialogVisibleimg: false,
      disabled: false,

      //文件设置属性
      fileList: [],
      filename: "", //文件标题

      checkedfeile: false, //新建文件设置密码

      checkedfeilepass: "", //新建文集密码

      propassboolan: false, //是否加密

      propassname: "", //设置的密码

      profiledata: -1, //修改文件的下标

      dialogVisiblefiled: false, //删除文件的弹出框

      proradio1booaln: "文件上传", //选择是上传文件还是自编文本

      proeditorContenttitle: "", //富文本框标题

      proeditorContent: "", //富文本框文本

      proeditochecked: false, //富文本是否加密

      proeditorContentboolan: "", //富文本框密码

      //成果展示页面属性
      dialogImageUrlresult: "",
      dialogVisibleresult: false,

      proachititle: "", //成果展示的标题
      proachisrc: "", //成果展示的网址
      proreresndex: -1, //删除成果的下标

      editor: null,

      projectid: "", //项目id

      profiledata: {}, //文件上传返回的材料

      prodowndata: {}, //成果上传

      projectlevelid: "", //二级项目id

      projectdownleveid: "", //成果展示id

      projectlevelcaozuo: -1, //显示二级id4

      homepagefalse: false, // 首页还是二级页面

      //二级页面属性

      prorjectleveldata: [], //二级项目数据

      proradio1booalneds: "文件上传", //选择是上传文件还是自编文本

      filenameeds: "", //二级文件标题

      propassboolaneds: false, //是否加密

      propassnameeds: "", //二级文件密码

      dialogVisiblefilededs: false, //删除弹窗

      dialogVisibleeds: false, //删除弹窗

      propassleveid: "", //项目id

      prodialogVisilevel: false, //二级目录删改

      propass3id: "", // 二级目录id

      propaindexid: 0, //选中的二级目录

      propathreedata: {}, //上传三级文件成功的返回值

      proidlistid: "", //文件列表id

      proidlistidurl: "", //文件列表路径

      prodlititle: false, //是否修改标题1

      prodlititlettwo: false, //是否修改标题2

      prodlititledata: {}, //上传标题1的图片

      prodlititleinputeds: "", //设置的标题1

      prodlititleinputedstwo: "", //设置的标题2

      prodlititleinput: "", //标题1

      prodlititleinputtwo: "", //标题2

      piliangdata: [], //批量上传成功的返回值

      inputbatch: "", //文件批量标题

      checkedbatch: false, //是否设置密码

      inputbatchpass: "", //密码

      piliangdatazuihou: [], //  最后批量上传

      checkedtchfile: false, //最后是否设置密码

      checkedtchinput: "", //最后批量密码

      probasicsboolan: false, //首次进入没有项目

      probasicsboolaneds: false, //首次进入没有二级文件

      probasicsbuploadimgdata: [], //文件设置图片文件属性

      projectidname: "", //头部名字

      browsefileVisible: false, //文件预览弹窗

      iframefileurl: "", //文件预览路径

      levelpassboolan: false, //
    };
  },
  mounted() {
    this.editor = new E("#editorElem");
    this.editor.config.onchange = (html) => {
      this.proeditorContent = html;
    };
    this.editor.create();
    this.projectid = this.$route.query.project_id;
    this.projectidname = this.$route.query.name;
    this.getbannerdata();

    this.getListdata();
  },
  methods: {
    //浏览pdf()
    browsepdf(item) {
      console.log(item.url);
      this.iframefileurl = item.url;
      this.browsefileVisible = true;
      console.log("浏览");
    },
    //关闭浏览pdf弹框
    handleClosebrows() {
      this.browsefileVisible = false;
    },

    //鼠标移出事件
    mouseoutcaozuo(item) {
      // console.log(item);
      this.probasioperation = -1;
      this.projectlevelcaozuo = -1;
    },

    //弹出框取消事件
    handleClose() {
      console.log(213123);
      this.dialogVisible = false; //关闭弹窗
      this.prodialogVisilevel = false; //判断一级或者二级操作
    },

    //删除操作隐藏密码框
    confirmbooanaindex(index) {
      console.log(index);
    },

    //返回上一级
    getback() {
      console.log(111);
      this.$router.go(-1);
    },

    //初次新建项目
    dialogxinjian() {
      console.log(111);
      this.dialogVisible = true;
      this.prodialogtitle = "新建";
      this.probasicsname = "";
    },

    //点击首页设置
    homepage(index) {
      this.probasicbolan = index;
      this.homepagefalse = false;
      // document.getElementsByClassName
    },

    //获取侧边栏数据
    // getListdata() {
    //   console.log(this.projectid);
    //   getList(this.projectid).then((res) => {

    //     console.log(res);

    //   });
    // },
    //获取图片数据
    getbannerdata() {
      getbanner("", this.projectid).then((res) => {
        this.fileList = res.data.data;
        // console.log(res);
      });
    },
    //删除图片
    handleRemove(file) {
      console.log(file);

      bannerimg("", "", "", file.id, 0, this.projectid).then((res) => {
        this.getbannerdata();
      });
      // console.log(111);
    },

    //文件上传成功
    bannerimgaddfile(res) {
      console.log(res);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.profiledata = res;
      }
    },
    //上传文件后保存
    savedatapro() {
      console.log(111);
      if (this.propassboolan) {
        if (this.propassname != "") {
          if (this.filename != "") {
            changeList(
              this.projectid,
              "file",
              "up",
              this.filename,
              this.profiledata.url,
              this.propassname
            ).then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          } else {
            this.$message({
              message: "请输入文件标题",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "请先输入密码",
            type: "warning",
          });
        }
      } else {
        if (this.filename != "") {
          console.log(this.profiledata);
          changeList(
            this.projectid,
            "file",
            "up",
            this.filename,
            this.profiledata.url
          ).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getListdata();
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        } else {
          this.$message({
            message: "请输入文件标题",
            type: "warning",
          });
        }
      }
    },
    //banner地图设置成功
    bannerimgadd(res) {
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        bannerimg(res.file_name, res.url, "", "", "", this.projectid).then(
          (resed) => {
            // console.log(resed);
            this.getbannerdata();
          }
        );
      }
    },
    //成果展示专栏上传
    beupload(file) {
      let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      let leix = ["jpeg", "jpg", "png"];

      if (leix.indexOf(fileSuffix) === -1) {
        this.$message({
          message: "请上传图片文件",
          type: "warning",
        });
        return false;
      }
    },
    //成果展示专栏上传成功
    bannerimgchietit(res) {
      console.log(res);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.prodowndata = res;
      }
    },

    //成果上传专栏保存
    savechietitdata() {
      console.log(1111);
      if (this.proachititle != "") {
        // proachisrc    网址
        changeList(
          this.projectid,
          "file",
          "down",
          this.proachititle,
          this.prodowndata.url,
          "",
          this.proachisrc
        ).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.proachititle = "";
            this.getListdata();
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入文件标题",
          type: "warning",
        });
      }
    },

    //获取初始数据
    getListdata() {
      getList(this.projectid).then((res) => {
        // console.log(res.data.data.up.children);

        this.probasicsdata = res.data.data.modular; //项目列表数据
        // console.log(this.probasicsdata.length );
        if (this.probasicsdata.length == 0) {
          console.log("没有文件");
          this.probasicsboolan = true;
        } else {
          this.probasicsboolan = false;
        }

        this.probasicfilelistdata = res.data.data.up.children; //文件列表
        this.probasicchengguolist = res.data.data.down.children; //成果展示文件列表

        console.log(res.data.data.up);

        this.prodlititleinputeds = res.data.data.up.title; //标题1
        this.probasicsbuploadimgdata = res.data.data.up.url; //标题1图片
        this.prodlititleinputedstwo = res.data.data.down.title; //标题2
        // console.log(this.probasicchengguolist);
      });
    },

    //侧边栏方法
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
      this.probasinoe = key;
    },
    //选择一级项目
    probasicheked(index, item) {
      this.probasicbolan = index + 1;
      this.homepagefalse = true;
      console.log(item.id);

      //二级项目id
      this.propassleveid = item.id;

      this.getListtwice_adddata();
    },
    //选择二级项目
    levecsscenter(index, item) {
      this.probasioperation = -1;
      console.log(index);
      this.procss46507 = index;

      this.propaindexid = index; //选中的目录
      if (item) {
        console.log(item);
        this.propass3id = item.id;
      }
    },

    //选中操作
    probasicoperation(index) {
      if (this.probasioperation == index) {
        this.probasioperation = -1;
      } else {
        this.probasioperation = index;
      }
    },
    //富文本框
    getContent() {
      // console.log(div1);
      console.log(this.proeditorContent);
      if (this.proeditorContenttitle != "") {
        if (this.proeditochecked) {
          //需要密码
          addtwice_change(
            this.proeditorContenttitle,
            this.propassleveid,
            this.projectid,
            "",
            this.propass3id,
            "",
            this.proeditochecked,
            "",
            this.proeditorContent
          ).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getListtwice_adddata();
              this.proeditorContenttitle = "";
              this.proeditorContent = "";
              this.proradio1booalneds = "文件上传";
            }
          });
        } else {
          //不需要密码

          addtwice_change(
            this.proeditorContenttitle,
            this.propassleveid,
            this.projectid,
            "",
            this.propass3id,
            "",
            "",
            "",
            this.proeditorContent
          ).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getListtwice_adddata();
              this.proeditorContenttitle = "";
              this.proeditorContent = "";
              this.proradio1booalneds = "文件上传";
            }
          });
        }
      } else {
        this.$message({
          message: "请输入标题",
          type: "warning",
        });
      }

      // document.getElementsByTagName('body')[0].style.setProperty('--test', 'blue');
    },
    //选择操作
    confirmbasi(index, value, name, item) {
      this.probasinoe = index;
      console.log(item);
      this.projectlevelid = item.id; //项目id
      this.dialogVisible = true;
      if (index == 0) {
        //修改名称
        this.probasicsname = item.name;
        this.prodialogtitle = name;
      } else {
        //新建二级项目
        this.probasicsname = "";
        this.prodialogtitle = name;
      }
    },
    //修改或者新建
    operationconfrm() {
      if (this.prodialogVisilevel) {
        if (this.probasicsname != "") {
          if (this.prodialogtitle == "新建") {
            addtwice_change(
              this.probasicsname,
              this.propassleveid,
              this.projectid,
              "",
              "",
              "",
              this.checkedfeilepass,
              "",
              "",
              "file"
            ).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });

                this.getListtwice_adddata();
                this.dialogVisible = false;
                this.prodialogVisilevel = false;
                this.projectlevelcaozuo = -1;
              }
            });
          } else if (this.prodialogtitle == "修改名称") {
            addtwice_change(
              this.probasicsname,
              this.propassleveid,
              this.projectid,
              "",
              this.propass3id,
              "",
              "",
              "",
              "",
              "file"
            ).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });

                this.getListtwice_adddata();
                this.dialogVisible = false;
                this.prodialogVisilevel = false;
                this.projectlevelcaozuo = -1;
              }
            });
          }
        } else {
          if (this.prodialogtitle == "删除") {
            addtwice_change(
              "",
              this.propassleveid,
              this.projectid,
              0,
              this.propass3id,
              "",
              "",
              "",
              "",
              "file"
            ).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });

                this.getListtwice_adddata();
                this.dialogVisible = false;
                this.prodialogVisilevel = false;
                this.projectlevelcaozuo = -1;
              }
            });
          } else {
            this.$message({
              message: "请输入名称",
              type: "warning",
            });
          }
        }
      } else if (this.prodialogtitle == "删除") {
        console.log(11111);
        Listtwice_add("", this.projectid, this.projectlevelid, 0).then(
          (res) => {
            // console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getListdata();
              this.dialogVisible = false;
              this.probasioperation = -1;
            }
          }
        );
      } else if (this.probasicsname == "") {
        this.$message({
          message: "请输入标题",
          type: "warning",
        });
      } else {
        if (this.prodialogtitle == "修改名称") {
          Listtwice_add(
            this.probasicsname,
            this.projectid,
            this.projectlevelid
          ).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getListdata();
              this.dialogVisible = false;
              this.probasioperation = -1;
            }
          });
          this.dialogVisible = false;
          this.probasioperation = -1;
        } else if (this.prodialogtitle == "新建") {
          Listtwice_add(this.probasicsname, this.projectid).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });

              this.getListdata();
              this.dialogVisible = false;
              this.probasioperation = -1;
              console.log("添加");
            }
          });
        } else {
          changeList(
            this.projectid,
            "file",
            "up",
            this.probasicsname,
            "",
            "",
            "",
            this.projectlevelid
          ).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getListdata();
              this.dialogVisible = false;
            }
          });
        }
      }
    },
    //上传图片的函数

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleimg = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    //上传文件的函数
    handleRemovefile(file, fileList) {
      console.log(file, fileList);
    },
    handlePreviewfile(file) {
      console.log(file);
    },
    handleExceedfile(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemovefile(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    //选择上传文件 或者批量上传
    prorradio() {
      console.log(1111);
      this.getListdata();

      // // console.log(document.getElementsByClassName("el-upload__input"));
      // // console.log(this.proradiobatch);
      // if (this.proradiobatch == "批量上传") {
      //   let uplod = document.getElementsByClassName("el-upload__input");
      //   uplod.forEach((item, index) => {
      //     // console.log(item);
      //     item.webkitdirectory = true;
      //   });
      // } else {
      //   let uplod = document.getElementsByClassName("el-upload__input");
      //   uplod.forEach((item, index) => {
      //     // console.log(item);
      //     item.webkitdirectory = false;
      //   });
      // }
    },
    //修改二级文件
    confirmdel(item) {
      console.log(item.id);
      this.projectlevelid = item.id; //项目id

      this.dialogVisiblefiled = true;
    },
    //关闭删除框
    handleClosefile() {
      this.proreresndex = -1;
      this.dialogVisiblefiled = false;
    },
    //删除二级文件
    dialogconfirmdel() {
      // console.log(123456);
      // console.log(this.probasicsdata[this.probasicbolan].children);

      if (this.proreresndex > -1) {
        changeList(
          this.projectid,
          "file",
          "down",
          "",
          "",
          "",
          "",
          this.projectdownleveid,
          0
        ).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getListdata();

            this.dialogVisiblefiled = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      } else {
        changeList(
          this.projectid,
          "file",
          "up",
          "",
          "",
          "",
          "",
          this.projectlevelid,
          0
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getListdata();
            this.dialogVisiblefiled = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });

        // this.$message({
        //   message: "删除成功",
        //   type: "success",
        // });
        //   this.dialogVisiblefiled = false;
      }
    },
    //成果展示上传功能函数
    handleRemoveresult(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreviewresult(file) {
      this.dialogImageUrlresult = file.url;
      this.dialogVisibleresult = true;
    },
    //删除的上传成果文件
    proresultindex(index, item) {
      // console.log("删除");
      console.log(item);
      this.projectdownleveid = item.id;
      this.dialogVisiblefiled = true;
      this.proreresndex = index;
    },

    //二级目录的操作
    caozuoprolevel(item, index) {
      console.log(item, index);
      this.propass3id = item.id;
      if (this.projectlevelcaozuo == index) {
        this.projectlevelcaozuo = -1;
      } else {
        this.projectlevelcaozuo = index;
      }
    },

    //查询二级目录
    getListtwice_adddata() {
      Listtwice_listdata(this.projectid, this.propassleveid).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.prorjectleveldata = res.data.data;
          this.propass3id = this.prorjectleveldata[0].id; //默认二级id

          this.probasicsboolaneds = false;

          this.projectlevelcaozuo = -1;
        } else {
          this.prorjectleveldata = [];
          this.probasicsboolaneds = true;
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },

    //二级文件上传成功
    bannerimgaddfileeds(res) {
      console.log(res);

      this.propathreedata = res;
    },

    //二级上传文件后保存
    savedataproeds() {
      if (this.filenameeds != "") {
        if (this.propassboolaneds) {
          if (this.propassnameeds != "") {
            addtwice_change(
              this.filenameeds,
              this.propassleveid,
              this.projectid,
              "",
              this.propass3id,
              this.propathreedata.url,
              this.propassnameeds
            ).then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });

                this.filenameeds = "";
                this.propassnameeds = "";
                this.getListtwice_adddata();
              }
            });

            //设置密码
          } else {
            this.$message({
              message: "请输入密码",
              type: "warning",
            });
          }
        } else {
          addtwice_change(
            this.filenameeds,
            this.propassleveid,
            this.projectid,
            "",
            this.propass3id,
            this.propathreedata.url
          ).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.filenameeds = "";
              this.getListtwice_adddata();
            }
          });
          //没有密码
        }
      } else {
        this.$message({
          message: "请输入标题",
          type: "warning",
        });
      }
    },
    //删除二级文件
    confirmdeleds(item) {
      console.log(item.id);
      this.proidlistid = item.id;
      this.probasicsname = item.name;
      this.proidlistidurl = item.url;
      this.dialogVisiblefilededs = true;
    },
    //编辑二级文件
    confirmbasieds(index, value, name, item) {
      // this.probasinoe = index;

      console.log(item.first_url);
      console.log(item.id);
      this.proidlistid = item.id;
      this.probasicsname = item.name;
      this.proidlistidurl = item.first_url;
      this.dialogVisibleeds = true;
    },
    //删除二级文件
    dialogconfirmdeleds() {
      console.log(123456);

      addtwice_change(
        this.probasicsname,
        this.propassleveid,
        this.projectid,
        0,
        this.propass3id,
        this.proidlistidurl,
        "",
        this.proidlistid
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.getListtwice_adddata();
          this.dialogVisiblefilededs = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },

    //修改二级文件名称
    operationconfrmeds() {
      console.log(1111);

      if (this.probasicsname != "") {
        addtwice_change(
          this.probasicsname,
          this.propassleveid,
          this.projectid,
          "",
          this.propass3id,
          this.proidlistidurl,
          "",
          this.proidlistid
        ).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getListtwice_adddata();
            this.dialogVisibleeds = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入文件名称",
          type: "warning",
        });
      }
    },

    //选择上传文件 或者批量上传
    prorradioeds() {
      // console.log(this.proradio1booalneds);
      // if (this.proradio1booalneds == "批量上传") {
      //   let uplod = document.getElementsByClassName("el-upload__input");
      //   uplod.forEach((item, index) => {
      //     // console.log(item);
      //     item.webkitdirectory = true;
      //   });
      // } else {
      //   let uplod = document.getElementsByClassName("el-upload__input");
      //   uplod.forEach((item, index) => {
      //     // console.log(item);
      //     item.webkitdirectory = false;
      //   });
      // }
    },
    //批量上传成功后
    biaotipiliang(res) {
      console.log(res);
      if (res.code == 200) {
        changeList(this.projectid, "file", "up", res.file_name, res.url).then(
          (resed) => {
            console.log(resed);
            this.piliangdata.push(resed.data);
          }
        );
      }
    },

    //批量上传保存数据
    savebatchfile() {
      console.log(111);
      console.log(this.checkedtchinput);
      console.log(this.piliangdatazuihou);

      this.piliangdatazuihou.forEach((item, index) => {
        console.log(item);
        addtwice_change(
          "",
          this.propassleveid,
          this.projectid,
          "",
          item.data.data,
          "",
          this.checkedtchinput,
          item.data.data
        ).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
          }
        });
      });
    },

    //修改二级标题1
    prodlititlebianji() {
      console.log(1111);
      this.prodlititle = !this.prodlititle;
      console.log(this.prodlititleinputeds);
      console.log(this.prodlititleinput);
      this.prodlititleinput = this.prodlititleinputeds;
    },
    //上传图片返回数据
    prodlititlebianjiosuccess(res) {
      console.log(res);
      this.prodlititledata = res;
    },

    //确定修改标题1
    confqueding() {
      console.log(1111);
      if (this.prodlititleinput != "") {
        changeList(
          this.projectid,
          "title",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          this.prodlititleinput,
          this.prodlititledata.url
        ).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.prodlititleinput = "";
            this.$message({
              message: res.data.msg,
              type: "success",
            });

            this.getListdata();
            this.prodlititle = false;
          }
        });
      } else {
        this.$message({
          message: "请先输入标题",
          type: "warning",
        });
      }
    },
    //标题1批量上传
    biaotione() {
      console.log(this.inputbatchpass);

      console.log(this.piliangdata);

      this.piliangdata.forEach((item, index) => {
        console.log(item.data);
        changeList(
          "",
          "file",
          "",
          "",
          "",
          this.inputbatchpass,
          "",
          item.data
        ).then((res) => {
          if ((index = this.piliangdata.length - 1)) {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            }
          }
        });
      });
    },

    //确定修改标题二
    prodlititlechenguo() {
      this.prodlititlettwo = !this.prodlititlettwo;
    },
    //提交标题二
    prodlititletijiao() {
      console.log(121212);

      console.log(this.prodlititleinputtwo);
      if (this.prodlititleinputtwo != "") {
        changeList(
          this.projectid,
          "title",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          this.prodlititleinputtwo
        ).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.prodlititleinputtwo = "";
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getListdata();
            this.prodlititlettwo = false;
          }
        });
      } else {
        this.$message({
          message: "请先输入标题",
          type: "warning",
        });
      }
    },

    //最后的批量上传
    batchuphtexteds(res) {
      if (res.code == 200) {
        addtwice_change(
          res.file_name,
          this.propassleveid,
          this.projectid,
          "",
          this.propass3id,
          res.url,
          this.proidlistid
        ).then((resed) => {
          this.piliangdatazuihou.push(resed);
        });
      }
    },
  },
  components: {
    Wangeditor,
  },
};
</script>

<style lang="scss" scoped>
.probasics {
  width: 100%;
  // height: 100%;
  background-color: #f8f9ff;

  // // background-color: $blue;
  // background-color: $textColor;
  .schsoolhader {
    width: 100%;
    // height: 10%;
    background-color: #fff;
    line-height: 70px;

    span {
      margin-left: 4%;
      font-weight: 800;
      color: #323b61;
    }
  }

  .probasicsmain {
    // height: 90%;
    width: 300px;
    background-color: #323b61;
    display: flex;

    .el-col-12 {
      width: 100%;
    }

    .probasicsnav {
      line-height: 66px;
      position: relative;

      .probasicsdiv {
        width: 300px;
        height: 66px;
        color: #fefefe;
        position: relative;
        display: flex;

        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        .menu-span {
          display: block;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .el-icon-menu {
          color: #fff;
          margin-right: 20px;
          margin-left: 20px;
          //       margin-right: 20px;
          // margin-left: 20px;
          margin-top: 24px;
        }

        .probahandle {
          width: 136px;
          height: 120px;
          background-color: #fff;
          position: absolute;
          text-align: center;
          left: 190px;
          top: 45px;
          z-index: 99;
          color: #999999;
          border-radius: 10%;
          cursor: pointer;

          div {
            width: 124px;
            height: 32px;
            margin-top: 3px;
            line-height: 32px;
            margin: 5px auto;
          }
        }
      }

      .el-icon-menu {
        color: #fff;
      }

      .el-icon-circle-plus-outline {
        margin-left: 120px;
        color: #fff;
        position: absolute;
        top: 25px;
        left: 150px;
      }

      .probasialter {
        .el-input {
          width: 55%;
          margin-right: 20px;
          margin-left: 10px;
        }
      }

      .dialogcss {
        .dialogdiv {
          .el-button--primary {
            background-color: #323b61;
            border: 0;
          }

          .dialogbuton {
            text-align: center;
          }
        }
      }
    }

    //中间模块
    .probasicscenter {
      width: 200px;
      margin-top: 20px;
      margin-left: 20px;
      background-color: #fff;

      .probasicdata {
        width: 200px;
        height: 60px;
        position: relative;
        // background-color: red;
        line-height: 60px;

        span {
          margin-left: 12px;
          margin-left: 12px;
          display: block;
          width: 160px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        i {
          position: absolute;
          top: 20px;
          right: 10px;
        }

        .probahandle {
          width: 136px;
          height: 120px;
          background-color: #fff;
          position: absolute;
          text-align: center;
          left: 115px;
          top: 35px;
          z-index: 99;
          color: #999999;
          border-radius: 10%;
          cursor: pointer;

          div {
            width: 124px;
            height: 32px;
            margin-top: 3px;
            line-height: 32px;
            margin: 5px auto;
          }
        }
      }
    }

    //右边模块
    .probasicsright {
      width: 1500px;
      height: 930px;
      background-color: #fff;
      margin: 25px;
      margin-bottom: 20px;

      .probasri {

        // width: 1300px;
        .probastitle {
          display: flex;
          margin: 20px;

          .probasblue {
            width: 4px;
            height: 20px;
            background-color: #323b61;
            margin-right: 15px;
          }

          .probasbann {
            font-weight: 800;
          }
        }

        .probasbottom {
          margin: 20px 55px;

          .probasimgtit {
            margin-bottom: 20px;
          }

          .probasimgupload {
            margin-bottom: 20px;
          }

          .probaszs {
            font-size: 14px;
            color: #999999;
          }
        }
      }

      .probascentre {
        margin: 20px;

        .procentre {
          display: flex;

          .probascenblue {
            width: 4px;
            height: 20px;
            background-color: #323b61;
            margin-right: 20px;
          }

          .probascenname {
            font-weight: 800;
          }

          .el-icon-edit {
            margin-left: 20px;
            margin-top: 5px;
          }

          //编辑标头
          .procendialog {

            // background-color: red;
            .el-input {
              margin-bottom: 20px;
            }

            .el-dialog__footer {
              text-align: center;
            }
          }
        }

        .probasfile {
          margin: 20px 30px;
          display: flex;

          .profilename {
            margin-bottom: 20px;
          }

          .profiledata {
            width: 425px;
            display: flex;
            // height: 40px;
            line-height: 35px;

            .el-input {
              margin-right: 20px;
            }
          }

          .propass {
            display: flex;
            margin-top: 20px;
            line-height: 40px;
            margin-bottom: 20px;

            span {
              font-size: 14px;
            }

            .el-checkbox {
              margin-right: 20px;
            }

            .el-input {
              width: 40%;
              margin-left: 20px;
              margin-right: 20px;
            }
          }

          .profilebut {
            background-color: #323b61;
            border: 0;
          }

          .profilelist {
            width: 804px;
            height: 236px;
            margin-left: 20px;
            border: 1px solid #f5f5f5;

            .profiletit {
              height: 46px;
              background-color: #f5f5f5;
              line-height: 46px;
              padding-left: 20px;

              display: flex;
              justify-content: space-between;
              padding-right: 20px;
            }

            .profiledataname {
              height: 187px;
              overflow: auto;

              /* overflow: scroll; */
              div {
                height: 46px;
                // background-color: red;
                border-bottom: 1px solid #f5f5f5;
                line-height: 46px;
                padding-left: 15px;
                position: relative;

                .el-icon-delete {
                  position: absolute;
                  top: 15px;
                  right: 50px;
                }

                .el-icon-view {
                  position: absolute;
                  top: 15px;
                  right: 90px;
                }

                .el-icon-edit-outline {
                  position: absolute;
                  top: 15px;
                  right: 10px;
                }

                span {
                  display: block;
                  width: 645px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            .profiledataname::-webkit-scrollbar {
              width: 12px;
              border-radius: 3px;
            }

            .profiledataname::-webkit-scrollbar-thumb {
              background: #323b61;
            }
          }

          .profileeldialogdata {

            // background-color: red;
            .profiledialog {
              display: flex;
              flex-flow: column wrap-reverse;
              text-align: center;

              img {
                width: 70px;
                margin: 0 auto;
                margin-bottom: 15px;
              }

              span {
                font-size: 16px;
              }
            }

            .el-dialog__footer {
              background-color: red;
            }
          }
        }

        //批量上传
        .probasfilebatch {
          width: 1500px;

          .el-input {
            width: 300px;
            margin-bottom: 10px;
            margin-left: 20px;
          }

          .upload-demo {
            margin-left: 20px;
          }

          .probasbatchcss {
            display: flex;

            height: 50px;
            line-height: 50px;
            margin-left: 20px;
            margin-bottom: 15px;
          }

          .el-button--primary {
            margin-left: 20px;
            margin-top: 15px;
          }
        }

        //文本框
        .prorichtext {
          width: 1300px;

          .el-input {
            width: 30%;
            margin: 20px;
          }
        }

        //
        .prorichtexteds {
          width: 1300px;

          .upload-demo {
            margin-left: 20px;
            margin-bottom: 25px;
          }

          .el-button--primary {
            margin-left: 20px;
          }
        }
      }

      .proachievement {
        .proachievnav {
          display: flex;
          margin: 20px;

          .proachiebule {
            width: 4px;
            height: 19px;
            background: #323b61;
            margin-right: 20px;
          }

          .proachietit {
            font-weight: 800;
          }

          .el-icon-edit {
            margin-left: 15px;
            margin-top: 5px;
          }

          .proachenguocss {
            display: flex;
            margin-left: 15px;
            margin-top: -5px;

            .el-input {
              margin-right: 15px;
            }
          }
        }

        .proachiedata {
          display: flex;

          .proachiuoload {
            // background-color: red;
            margin-left: 40px;
            // div {
            //   //       ul{
            //   // background-color: red;
            //   //       }
            //   //       .el-upload-list {
            //   //         display: none;
            //   //         background-color: red;
            //   //       }
            // }
          }

          .proachiinput {
            width: 300px;
            margin-left: 10px;

            .el-input {
              margin-bottom: 15px;
              width: 300px;
            }

            .el-button {
              background: #323b61;
              border: 0;
            }
          }

          .proachfile {
            margin-left: 10px;
            margin-top: -40px;

            .proachfilecss {
              display: flex;

              .proachfilimg {

                // background: url("../assets/logo.png") no-repeat;
                img {
                  width: 162px;
                  height: 126px;
                  position: absolute;
                }

                width: 162px;
                height: 126px;
                background-size: 162px;
                margin-right: 15px;

                div {
                  width: 162px;
                  height: 126px;
                  background: #333333;
                  opacity: 0.5;
                  color: #fff;
                  text-align: center;
                  line-height: 126px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//选中的图标
.operation {
  background: #f5f5f5;
}

//选中的二级项目
.css46507c {
  background-color: #e3e7f8;
}
</style>